/*
 * 업무구분 : 고객관리
 * 화면 명 : MSPCM230M
 * 화면 설명: 더헬스고객
 * 접근권한: FC, 지점장
 * 작 성 일: 2023.01.18
 * 작 성 자: 서영화
 */

<template>
  <ur-page-container @on-header-left-click="fn_ClickBackBtn" @action-search-click="fn_OpenMSPCM231P()" @on-scroll-bottom="fn_LoadingData()" 
                     class="msp" title="더헬스고객" :show-title="true" type="subpage" action-type="search" :topButton="true">
    <template #frame-header-fixed>

      <!-- 검색결과 영역 start-->
      <ur-box-container  v-if="isSearched && !isCleared" alignV="start" direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box  default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <template v-for="item in searchedItems">
            <mo-tab-label v-if="!$bizUtil.isEmpty(item.value)" @click="fn_OpenMSPCM231P" :idx="item.idx" :key="item.idx" >
              <span class="txt">{{ item.value }}</span> 
            </mo-tab-label>
          </template>
        </mo-tab-box>
      </ur-box-container>
      <!-- 검색결과 영역 end-->

      <!-- 데이터개수 및 체크박스 영역 start -->
      <ur-box-container alignV="start" componentid="ur-box-container_001" direction="column" class="ns-check-sum left-list">
        <mo-list-item>
         <mo-checkbox v-model="isCheckAll" @click="fn_ClickCheckAll" :disabled="isNoData"  size="small"/>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span v-if="checkedCount <= 0" class="sum">총 <strong class="crTy-blue3">{{ totalCount }}</strong> 명</span>
              <span v-else class="sum"><strong class="crTy-blue3">{{ checkedCount }}</strong> 명 선택</span>
            </div>
          </div>
					<div class="list-item__contents fexTy3">
            <span class="ns-tool-tip ml10">
								<mo-icon id="location_MSPCM230M" icon="msp-tool-tip"></mo-icon>
								<mo-tooltip target-id="location_MSPCM230M" class="tool-tip-box">※ 서비스 이용, 간강분석, 챌린지 참여일자는 고객 별로 가장 최근일자가 표시됩니다.</mo-tooltip>                  
							</span>
          </div>					
        </mo-list-item>
      </ur-box-container>
      <!-- 데이터개수 및 체크박스 영역 end -->
    </template>

    <!-- Content영역 start -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isNoData" alignV="start" componentid="ur-box-container_002" direction="column" class="ns-check-list pdtb20-list">
        <!-- ur-box-container class="pb60" 이 부분 추가 : 체크했을 때, bottomsheet로 데이터가 가려서 필요  -->
        <ur-box-container class="pb60"> 
          <mo-list :list-data="cmgradUserSVO">
            <template #list-item="{ index, item }">
              <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <mo-checkbox v-model="item.checked" @input="fn_ClickCheckPartial" :ref="`chk${index}`"/> 
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <!-- 고객명 -->
                    <span @click.stop="fn_OpenCustInfoBs(item)" class="name txtSkip">{{ item.custNm }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 생년월일 -->
                    <span class="crTy-bk1">{{ item.ralDob }}</span><em>|</em>
                    <!-- 성별 -->
                    <span class="crTy-bk1">{{ item.sxdsCd }}</span><em>|</em>
                    <!-- 휴대번호 -->
                    <span class="crTy-bk1">{{ item.celno }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 더헬스 가입 -->
                    <span class="crTy-bk7 fs14rem mr20 min70">더헬스 가입</span><span class="crTy-bk1">{{ item.thjoDt }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 서비스 이용 -->
                    <span class="crTy-bk7 fs14rem mr20 min70">서비스 이용</span><span class="crTy-bk1">{{ item.receSerDt }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 건강분석 -->
                    <span class="crTy-bk7 fs14rem mr20 min70">건강분석</span><span class="crTy-bk1">{{ item.receHealsDt }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 챌린지 참여 -->
                    <span class="crTy-bk7 fs14rem mr20 min70">챌린지 참여</span><span class="crTy-bk1">{{ item.receChanDt }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 챌린지명 -->
                    <span class="crTy-bk7 fs14rem mr20 min70">챌린지명</span><span class="crTy-bk1">{{ item.receChanNm }}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>   
        </ur-box-container>
      </ur-box-container>     

      <!-- NoData 영역 start -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_008" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역 end -->
      
    </ur-box-container>
    <!-- Content영역 end -->

  </ur-page-container>
</template>

<script>

import Msg       from '@/systems/webkit/msg/msg'
import MSPCM231P from '@/ui/cm/MSPCM231P'         // 더헬스고객 목록 검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  name: 'MSPCM230M', 
  screenId: 'MSPCM230M', 
  components: {    
    MSPCM231P,
  },//componets,

  props:{},//props

  data () {
    return {
      title: '더헬스 고객',

      lv_inputParam: {},        // 서비스호출 파라미터 

      /**
       * 사용자 정보 변수
       */
      userInfo: {},             // 조직 및 컨설턴트 정보
      isMngr: false,            // 지점장 여부

      /**
       * 화면 변수
       */
      isNoData: false,         
      isLoading: false,

      /**
       * 더보기 key 변수 
       */ 
      stndKeyList: [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}], 
      pageRowCnt: '20',       
      stndKeyVal: 'start',
      gradPag: '1',

      /**
       * 체크박스 변수
       */ 
      isCheckAll: false,        // 전체체크 여부 
      totalCount: 0,            // 전체 고객수 
      selectedItems: [],        // 선택된 고객 리스트 

      /**
       * 조회 변수
       */ 
      cmgradUserSVO: [],        // 더헬스고객 조회 데이터 
      searchedCustNm: '',       // 고객명

      searchedItems: {},        // 검색결과 데이터 

      /**
       * 제어 변수 
       */
      isSearched: false,        // 조회이력 저장 변수 
      isCleared: false,         // 조회조건 초기화 여부
      
      // 팝업 연동 
      popup231: {},             // MSPCM231P 더헬스고객 목록 검색
      popup117: {},             // MSPCM117P 고객상세

    }
  },//data

  created () {
    
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)     // backKey Event 등록
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')                       // post 호출 전 Progress 타입 설정

    this.userInfo = this.getStore('userInfo').getters.getUserInfo                   // 사용자 정보

    /**
     * 지점장 여부를 확인한다.
     * 해당 사용자는 본인이 아닌 FC번호로 조회가능하다.
     */
    if(this.$cmUtil.isSrchOrgCombo() === 'Y') this.isMngr = true

    this.fn_Submit()
    
  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  beforeDestroy () {
       
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)      //backKey Event 해제
    window.vue.getStore('progress').dispatch('FULL')                                  // post 호출 전 Progress 타입 설정

  },//beforeDestroy

  watch: {
    
    /**
     * 전체 체크여부를 확인한다.
     */
    checkedCount(){
      let cnt = this.cmgradUserSVO.length 
      if(this.checkedCount == cnt && this.cmgradUserSVO.length != 0){ 
        this.isCheckAll  = true
      }else{
        this.isCheckAll  = false
      }
    },
  },//watch

  computed: {
    
    /**
     * 선택된 데이터 개수를 구한다.
     */ 
    checkedCount(){
      return this.cmgradUserSVO.filter(item => item.checked).length
    },

    /**
     * 선택된 데이터를 추출한다. 
     */
    checkedList(){
      return this.cmgradUserSVO.filter(item => item.checked)
    },
  },//computed

  methods: {

    fn_OnClickListItem(index) {
      this.$refs[`chk${index}`].onClick()
    },

    /**
     * @description 초기 조회조건으로 초기화 한다.
     */
    fn_ClearCond(){

      this.searchedCustNm = ''   
      this.totalCount     = 0        
      this.isSearched     = false     
      this.searchedItems  = {}       
      this.isCleared      = true             

    },
    
    /**
     * @description 체크된 값이 있는지 확인 후, 뒤로가기 실행 함수를 호출한다.
     */
    fn_ClickBackBtn(){
      
      if (this.checkedCount > 0) {
        this.fn_confirm()
        return 
      } 
      
      this.fn_BackToPrevious()

    },
    
    /**
     * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
     *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
     */
    fn_BackToPrevious(){

      if(this.isSearched){  
        this.fn_ClearCond()
        this.fn_Submit()
      }else{
        if (this.checkedCount > 0) {
          this.fn_confirm()
        } else {
          // 메뉴로 진입했으면 홈화면으로 이동한다.
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }

    },
    
    /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     */
    fn_Submit(){

      // 초기화  
      this.totalCount  = 0  
      this.gradPag     = '1'
      this.stndKeyVal  = 'start'               
      this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      this.isNoData    = false
      this.cmgradUserSVO.splice(0)
      
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      // 조직 및 컨설턴트 데이터 세팅 
      this.lv_inputParam.cnsltNo  = this.userInfo.userId        

      this.lv_inputParam.gradPag = this.gradPag
      this.lv_inputParam.conTyp  = '0'
      this.lv_inputParam.custNm  = this.searchedCustNm 
      
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')     // post 호출 전 Progress 타입 설정

      // 서비스 호출 
      this.fn_GetService()

    },
    
    /**
     * @description 더헬스고객 목록 조회 서비스 호출 후, 콜백 데이터를 처리한다.
     * @callback    function(response) 
     */
    fn_GetService() {

      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM65S1'
      // const trnstId = 'txMSPCM65S1'
      const trnstId = 'txTSSCM49S5'
      const auth    = 'S'

      let lv_Vm   = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            
            let responseList = response.body.cmgradUserSVO;   // 더헬스고객 리스트 

            if(responseList !== null){

              // 더헬스고객 조회 데이터 가공 
              responseList.forEach((item, index) => {

                item.ralDob      = lv_Vm.$commonUtil.dateDashFormat(item.ralDob)      
                item.sxdsCd      = (item.sxdsCd === 'M') ? '남성' : '여성'             
                item.celno       = lv_Vm.$bizUtil.telNoWithHyphen(item.celno)       
                item.thjoDt      = lv_Vm.$commonUtil.dateDashFormat(item.thjoDt)      
                item.receHealsDt = lv_Vm.$commonUtil.dateDashFormat(item.receHealsDt) 
                item.receChanDt  = lv_Vm.$commonUtil.dateDashFormat(item.receChanDt)  
                lv_Vm.$set(responseList[index], 'checked', false)

              })

              // 더헬스고객 데이터 저장 
              if(lv_Vm.stndKeyVal !== 'start'){
                responseList.forEach((item) => { lv_Vm.cmgradUserSVO.push(item) })
              }else{
                lv_Vm.cmgradUserSVO = responseList  // 1 page
              }

              // 더보기 데이터 저장 
              let gradCt = Number(response.body.gradCt) // 조회된 데이터 개수 
              if(responseList.length >= gradCt){
                lv_Vm.stndKeyVal = 'end'    // 다음페이지 데이터 존재안함(마지막 페이지)
              }else{
                lv_Vm.stndKeyVal = 'more'   // 다음페이지 데이터 존재함
                let currentPage = parseInt(lv_Vm.gradPag) + 1
                lv_Vm.gradPag = currentPage.toString()
              }

              // 조회 총 카운트
              lv_Vm.totalCount = response.body.gradCt

            }
            
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false

          // 데이터 유무 
          if (lv_Vm.cmgradUserSVO.length === 0) {
            lv_Vm.isNoData = true    
          } else {
            lv_Vm.isNoData = false  
          }

        })
        .catch(function (error) {
          window.vue.error(error)
        })

    },
    
    /**
     * @description 조회목록을 전체선택 한다. 
     */
    fn_ClickCheckAll(){

      this.$nextTick(() =>{
        
        if(this.cmgradUserSVO.length > 0){
          this.cmgradUserSVO.forEach((item, index) => {
            if(this.isCheckAll){
              this.cmgradUserSVO[index].checked = true
            }else{
              this.cmgradUserSVO[index].checked = false
            }
          })

          this.selectedItems = [] // 초기화
          this.selectedItems = this.checkedList

          // BottomSheet 제어 
          this.fn_ControlBottomSheet()
        }
      })

    },

    /**
     * @description 조회목록을 부분선택 한다.
     */
    fn_ClickCheckPartial(){

      this.selectedItems = [] // 초기화
      this.selectedItems = this.checkedList

      // BottomSheet 제어 
      this.fn_ControlBottomSheet()

    },

    /**
     * @description BottomSheet를 제어한다.
     *              조회목록을 부분/전체선택하면 노출된다.
     */
    fn_ControlBottomSheet() {
      this.$cmUtil.setSelectedCustList(this, this.checkedList)

    },

    /**
     * @description 데이터를 로딩한다.
     */
    fn_LoadingData(){

      if (!this.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (this.isCheckAll && this.stndKeyVal !== 'end') {
          this.isCheckAll = false
        }

        window.vue.getStore('progress').dispatch('PART') // post 호출 전 Progress 타입 설정

        this.fn_GetService()
      }

    },


    /**
     * @description 더헬스고객 고객검색 팝업을 제어한다.
     */
    fn_OpenMSPCM231P(){

      this.popup231 = this.$bottomModal.open(MSPCM231P, {
        properties: {
          pPage: this.$options.screenId,
          pSearchedCustNm: this.searchedCustNm,     // 고객명
          pSelectedItems: this.searchedItems,       // 조회조건 
        },

        listeners: {
          searchPopup: (pData) => {
             // 모달 닫기
            this.$bottomModal.close(this.popup231)
            
            this.searchedCustNm = pData.rtnData.searchedCustNm  
            this.searchedItems  = pData.rtnData.searchedItems    
            this.isSearched     = true                         
            this.isCleared      = pData.isCleared     
                       
            if (this.isCleared) this.isSearched = false

            // 더헬스고객 조회 
            this.fn_Submit()

          }
        }
      })

    },

    /**
     * @description 고객명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 더헬스고객 데이터 
     */
    fn_OpenCustInfoBs (item) {

      let lv_Vm = this
      let nmUiObject = {}

      nmUiObject = {
        chnlCustId : item.chnlCustId,         //고객채널 ID
        parentId   : this.$options.screenId,  //화면ID+
        cnsltNo : this.userInfo.userId,
        custNm : item.custNm,
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
        return
      })
      
    },

    /**
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     */
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              this.fn_BackToPrevious()
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
    
 },// methods

}//export default


</script>