/*
 * 업무구분 : 고객관리
 * 화면 명 : MSPCM231P
 * 화면 설명: 더헬스고객 > 고객검색
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.01.18
 * 작 성 자: 서영화 
 */

<template>
  <ur-page-container title="고객검색" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <!-- 고객명 -->
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="sch-box">
          <mo-text-field v-model="searchedCustNm" class="form-input-name" underline clearable placeholder="고객명을 입력해 주세요." />
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_01" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickClear">초기화</mo-button>
            <mo-button componentid="mo_button_02" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClickSearch">조회</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

export default {

  name: 'MSPCM231P', 
  screenId: 'MSPCM231P', 
  components: {},//componets,

  props:{
    pPage: String,            // 부모 페이지명
    pSearchedCustNm: String,  // 고객명
    pSelectedItems: {         // 조회조건 
      type: Object,
      default: {}
    }
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {
      lv_ReturnVal: {},        // 리턴값
      isReturn: false,         // 리턴값 세팅여부 
      isCleared: false,        // 초기화여부

      searchedCustNm: '',      // 고객명 

      selectedSearchItems: {}, // 조회조건
    }
  },//data

  created () {
    
    window.vue.getStore('progress').dispatch('FULL')      // post 호출 전 Progress 타입 설정.
    
    this.searchedCustNm = this.pSearchedCustNm 

    /**
     * 조회조건 결과 설정 
     */ 
    if(JSON.stringify(this.pSelectedItems) === '{}'){
      // 초기화 
      this.selectedItems = {
        searchedCustNm: {title:'고객', value: '', idx: 1}, 
      }
    }else{
      this.selectedItems  = this.pSelectedItems           
    }

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {

    searchedCustNm () {
      let englishOrKorean = /[A-Za-z]/
      if (!this.$bizUtil.isEmpty(this.searchedCustNm)) {
        if (englishOrKorean.test(this.searchedCustNm)) {
          this.searchedCustNm = this.searchedCustNm.toUpperCase()
        }
      }
    }

  },//watch

  computed: {},//computed

  methods: {
    
    /**
     * @description 선택된 조건값을 초기 조건값으로 초기화 한다.
     */
    fn_ClickClear(){
      this.searchedCustNm = ''   
    },
    
    /**
     * @description 부모화면에 전달할 리턴 데이터를 세팅해준다.
     */
    fn_SetReturnVal () {

      // 초기화 
      this.lv_ReturnVal = {} 
      
      // 리턴값 설정
      this.lv_ReturnVal.searchedCustNm = this.searchedCustNm.trim()  

      // 조회조건 결과 설정
      this.selectedItems.searchedCustNm.value = this.searchedCustNm.trim()
      this.lv_ReturnVal.searchedItems  = this.selectedItems

      // 초기화 여부
      if (this.$bizUtil.isEmpty(this.lv_ReturnVal.searchedCustNm)) {
        this.isCleared = true
      }

      // 리턴값 세팅여부 
      this.isReturn = true 
 
    },
    
    /**
     * @description 리턴 데이터 세팅 후, 팝업을 닫는다.
     */
    fn_ClickSearch(){

      // 리턴값 세팅
      this.fn_SetReturnVal()

      if (this.isReturn) {
        this.$emit('searchPopup', { rtnData: this.lv_ReturnVal, isCleared: this.isCleared });
      }

    },
 },// methods

}//export default


</script>